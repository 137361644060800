<!--
 * @Author: lbh
 * @Date: 2022-12-10 15:27:03
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 14:57:43
 * @Description: QR Code點餐_圖文2
-->
<template>
  <div
    :class="`edit_${Xindex}_box qrCodeGraphicText2-box__${configs.type}  px-qrCodeGraphicText2-box__${configs.type}`"
    @click="clickItem('box')"
    :style="`background-color:${configs.bgColor};`"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="sub-title"
      v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
    ></h2>

    <div
      class="desc-t"
      :style="`text-align:${configs.descAlign}`"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
    ></div>
    <div class="t-box">

      <div
        class="center-box"
        :class="`cb__${configs.type}`"
      >
        <div
          class="c-box"
          v-html="$util.rex.getHtml(configs[`${nowL}centerTitle`] || configs.centerTitle)"
        ></div>
      </div>

      <div
        class="items"
        v-for="(item,index) in configs.items"
        :key="index + '2'"
        :style="`transform: rotate(${index * (360 / configs.items.length)}deg);`"
      >
        <div
          class="item"
          :style="`transform: rotate(-${index * (360 / configs.items.length)}deg);background-color:${item.bgColor}`"
          v-html="$util.rex.getHtml(item[`${nowL}text`] || item.text)"
        ></div>
      </div>
      <template v-if="configs.type == 1">
        <div
          class="items items_2"
          v-for="(item,index) in configs.items"
          :key="index + '1'"
          :style="`transform: rotate(${index * (360 / configs.items.length) + (360 / configs.items.length / 2)}deg);`"
        >
          <div class="icon-box">
            <selfIcon
              width="100%"
              height="100%"
              :color="item.bgColor"
              src="https://ricepon.oss-cn-hongkong.aliyuncs.com//home/167108600676178f17d39Ricepon.png"
            />
          </div>

        </div>
      </template>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
import selfIcon from "../com/icon.vue"
export default {
  components: {
    selfIcon
  },
  name: "qr-code-graphic-text2",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          bgColor: "",
          itemPadding: "0",
          items: [],
          subTitle: "",
          title: "",
          type: '',
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and(min-width:500px) {
  .qrCodeGraphicText2-box__0 {
    .title {
      font-size: 30px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding-top: 30px;
    }
    .sub-title {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 6px;
    }
    .desc-t {
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #101010;
      padding: 15px 320px 0;
    }
    .t-box {
      width: 640px;
      height: 640px;
      margin: 30px auto 0;
      padding-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center-box {
        width: 318px;
        height: 306px;
        background: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .c-box {
          background: #fff;
          width: 242px;
          height: 230px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 42px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            width: 28px;
            height: 130px;
            top: -116px;
            background-color: #fff;
            transform: rotateZ(135deg) translateX(38px);
            z-index: 10;
          }
          &::before {
            content: "";
            position: absolute;
            width: 28px;
            height: 130px;
            bottom: -116px;
            background-color: #fff;
            transform: rotateZ(135deg) translateX(-38px);
            z-index: 10;
          }
        }
        &::after {
          position: absolute;
          content: "";
          border-width: 30px;
          border-style: solid;
          border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #ccc #ccc;
          top: -20px;
          transform: translateX(-48px);
        }
        &::before {
          position: absolute;
          content: "";
          border-width: 30px;
          border-style: solid;
          border-color: #ccc #ccc rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
          bottom: -20px;
          transform: translateX(48px);
        }
      }
      .items {
        position: absolute;
        top: 0;
        height: 320px;
        transform-origin: bottom;
        z-index: 20;
        transition: 0.5s;
        &.items_2 {
          height: 280px;
          top: 40px;
          .icon-box {
            height: 44px;
            width: 28px;
          }
        }
        .item {
          width: 130px;
          height: 130px;
          background: var(--themeColor);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          transition: 0.5s;
          text-align: center;
        }
      }
    }
  }
  .qrCodeGraphicText2-box__1 {
    .title {
      font-size: 30px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
      padding-top: 70px;
    }
    .sub-title {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 6px;
    }
    .t-box {
      width: 640px;
      height: 640px;
      margin: 70px auto 0;
      padding-bottom: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center-box {
        width: 318px;
        height: 306px;
        background: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &::after {
          position: absolute;
          border-style: solid;
          content: "";
          border-color: #ccc #ccc rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) !important;
          border-width: 25px;
          z-index: 10;
          top: -16px;
          transform: translateX(-2px) rotateZ(42deg);
          transform-origin: left top;
        }
        &::before {
          position: absolute;
          content: "";
          border-color: #ccc transparent transparent #ccc;
          border-width: 25px;
          border-style: solid;
          z-index: 10;
          bottom: 18px;
          transform: translateX(36px) rotateZ(-42deg);
          transform-origin: bottom right;
        }
        .c-box {
          background: #fff;
          width: 242px;
          height: 230px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 42px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 30px;
            border-style: solid;
            border-color: #fff #fff transparent transparent;
            top: -16px;
            transform: rotateZ(53deg);
            transform-origin: top right;
            z-index: 2;
            background-color: transparent;
          }
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 30px;
            border-style: solid;
            border-color: transparent transparent #fff #fff;
            bottom: -18px;
            transform: rotateZ(45deg);
            transform-origin: bottom left;
            z-index: 2;
            background-color: transparent;
          }
        }
      }
      .items {
        position: absolute;
        top: 0;
        height: 320px;
        transform-origin: bottom;
        z-index: 20;
        transition: 0.5s;
        &.items_2 {
          height: 280px;
          top: 40px;
          .icon-box {
            height: 44px;
            width: 28px;
          }
        }
        .item {
          width: 130px;
          height: 130px;
          background: var(--themeColor);
          border-radius: 50%;
          text-align: center;
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          transition: 0.5s;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-qrCodeGraphicText2-box__0,
  .px-qrCodeGraphicText2-box__1 {
    .title {
      font-size: 16px !important;
    }
    .sub-title {
      font-size: 12px !important;
    }
    .desc-t {
      padding-top: 15px;
      font-size: 14px;
    }
    .items {
      .item {
        // width: 54px !important;
        // height: 54px !important;
      }
    }
  }
}

@media screen and(max-width:500px) {
  .px-qrCodeGraphicText2-box__0 {
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-top: 15px;
      text-align: center;
    }
    .sub-title {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 2px;
    }
    .desc-t {
      font-size: 12px;
      padding: 15px 10px 0;
    }
    .t-box {
      width: 100%;
      height: 100vw;
      margin-top: 26px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center-box {
        width: calc(100vw / 2.25);
        height: calc(100vw / 2.25);
        background: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .c-box {
          background: #fff;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 25px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            width: 16px;
            height: 50px;
            top: -32px;
            background-color: #fff;
            transform: translateX(-10px) rotateZ(135deg);
            z-index: 10;
          }
          &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 50px;
            bottom: -32px;
            background-color: #fff;
            transform: translateX(10px) rotateZ(135deg);
            z-index: 10;
          }
        }
        &::after {
          position: absolute;
          content: "";
          border-width: 15px;
          border-style: solid;
          border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #ccc #ccc;
          top: -10px;
          transform: translateX(-29px);
        }
        &::before {
          position: absolute;
          content: "";
          border-width: 15px;
          border-style: solid;
          border-color: #ccc #ccc rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
          bottom: -10px;
          transform: translateX(29px);
        }
      }
    }
    .items {
      position: absolute;
      top: 0;
      height: calc(50vw - 8px);
      transform-origin: bottom;
      z-index: 20;
      transition: 0.5s;
      .item {
        width: 67px;
        height: 67px;
        background: var(--themeColor);
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        transition: 0.5s;
        line-height: 16px;
      }
    }
  }
  .px-qrCodeGraphicText2-box__1 {
    overflow: hidden;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-top: 26px;
      text-align: center;
    }
    .sub-title {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 2px;
    }
    .t-box {
      width: 100%;
      height: 100vw;
      margin-top: 26px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center-box {
        width: calc(100vw / 2.25);
        height: calc(100vw / 2.25);
        background: #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .c-box {
          background: #fff;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 25px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          text-align: center;
          &::after {
            content: "";
            position: absolute;
            border-width: 15px;
            border-style: solid;
            top: -25px;
            border-color: transparent #fff #fff transparent;
            transform: translateX(10px) rotateZ(-30deg);
            z-index: 10;
          }
          &::before {
            content: "";
            position: absolute;
            border-width: 15px;
            border-style: solid;
            bottom: -25px;
            border-color: transparent transparent #fff #fff;
            transform: translateX(-10px) rotateZ(60deg);
            z-index: 10;
          }
        }
        &::after {
          position: absolute;
          content: "";
          border-width: 15px;
          border-style: solid;
          border-color: #ccc #ccc transparent transparent;
          top: 12px;
          transform-origin: right top;
          transform: translateX(5px) rotateZ(55deg);
          z-index: 11;
        }
        &::before {
          position: absolute;
          content: "";
          border-width: 15px;
          border-style: solid;
          border-color: transparent transparent #ccc #ccc;
          bottom: 12px;
          transform-origin: left bottom;
          transform: translateX(-5px) rotateZ(55deg);
          z-index: 11;
        }
      }
    }
    .items {
      position: absolute;
      top: 0;
      height: calc(50vw - 8px);
      transform-origin: bottom;
      z-index: 20;
      transition: 0.5s;
      &.items_2 {
        height: calc(50vw - 30px);
        top: 22px;
        .icon-box {
          height: 23px;
          width: 14px;
        }
      }
      .item {
        width: 67px;
        height: 67px;
        background: var(--themeColor);
        border-radius: 50%;
        text-align: center;
        // font-size: 13px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        // line-height: 16px;
      }
    }
  }
  .qrCodeGraphicText2-box__1 {
    .item {
      font-size: 46px;
    }
  }
}
</style>1