<!--
 * @Author: lbh
 * @Date: 2022-10-09 18:14:38
 * @LastEditors: lzh
 * @LastEditTime: 2022-12-02 14:39:11
 * @Description: png 設置顏色
-->
<template>
  <!-- -webkit-mask-image 兼容市面上大部分瀏覽器 -->
  <!-- mask-image 兼容火狐瀏覽器 -->
  <div
    class="icon-box"
    :style="`-webkit-mask-image: url(${src});mask-image: url(${src});width:${width};height:${height};background:${color};`"
  >
  </div>
</template>

<script>
export default {
  name: "self-icon",
  props: {
    // 圖片地址
    src: {
      default () {
        return '';
      }
    },
    // 容器寬
    width: {
      default () {
        return '14px';
      }
    },
    // 容器高
    height: {
      default () {
        return '14px';
      }
    },
    // 填充色
    color: {
      default () {
        return 'var(--themeColor)'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.icon-box {
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}
</style>
